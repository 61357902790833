import { Dimensions } from '@lib/types'

export const ptMap = {
  '0px': 'pt-0',
  '20px': 'pt-5',
  '40px': 'pt-5 lg:pt-10',
  '48px': 'pt-8 lg:pt-12',
  '60px': 'pt-12 lg:pt-15',
  '80px': 'pt-15 lg:pt-20',
}

export const pbMap = {
  '0px': 'pb-0',
  '20px': 'pb-5',
  '40px': 'pb-5 lg:pb-10',
  '48px': 'pb-8 lg:pb-12',
  '60px': 'pb-12 lg:pb-15',
  '80px': 'pb-15 lg:pb-20',
}

export const ptAtLgMap = {
  '0px': 'lg:pt-0',
  '20px': 'lg:pt-5',
  '40px': 'lg:pt-10',
  '48px': 'lg:pt-12',
  '60px': 'lg:pt-15',
  '80px': 'lg:pt-20',
}

export const pbAtLgMap = {
  '0px': 'lg:pb-0',
  '20px': 'lg:pb-5',
  '40px': 'lg:pb-10',
  '48px': 'lg:pb-12',
  '60px': 'lg:pb-15',
  '80px': 'lg:pb-20',
}

export const cardWidthMap = {
  '5-up': 'w-full sm:w-grid-2 lg:w-grid-3 xl:w-grid-5',
  '4-up': 'w-full sm:w-grid-2 lg:w-grid-3 xl:w-grid-4',
  '3-up': 'w-full sm:w-grid-2 lg:w-grid-3',
  '2-up': 'w-full sm:w-grid-2',
}

export const card2WidthMap = {
  '5-up': 'w-full sm:w-grid-2 lg:w-grid-3 xl:w-grid-5',
  '4-up': 'w-full sm:w-grid-2 xl:w-grid-4',
  '3-up': 'w-full sm:w-grid-2 lg:w-grid-3',
  '2-up': 'w-full sm:w-grid-2',
}

export const duplexWidthMap = {
  '3/12': 'w-full md:w-grid-3/12',
  '9/12': 'w-full md:w-grid-9/12',
  '5/12': 'w-full md:w-grid-5/12',
  '7/12': 'w-full md:w-grid-7/12',
}

export const layoutMap = {
  '3/9': [duplexWidthMap['3/12'], duplexWidthMap['9/12']],
  '5/7': [duplexWidthMap['5/12'], duplexWidthMap['7/12']],
  '7/5': [duplexWidthMap['7/12'], duplexWidthMap['5/12']],
  '9/3': [duplexWidthMap['9/12'], duplexWidthMap['3/12']],
}

/**
 * The imageDimensions token arrays correspond to diff ranges of
 * image sizes for a given block or layout - all tied to breakpoints
 *
 * 'sm': '640px',
 * // => @media (min-width: 640px) { ... }
 *
 * 'md': '768px',
 * // => @media (min-width: 768px) { ... }
 *
 * 'lg': '1024px',
 * // => @media (min-width: 1024px) { ... }
 *
 * 'xl': '1280px',
 * // => @media (min-width: 1280px) { ... }
 *
 * '2xl': '1536px',
 * // => @media (min-width: 1536px) { ... } #}
 *
 * 6-col-max:     lg: 16vw, md: 33vw, sm: 50vw
 * 5-col-max:     lg: 20vw, md: 33vw, sm: 50vw
 * 4-col-max:     lg: 25vw, md: 50vw, sm: 100vw
 * 3-col-max:     lg: 33vw, md: 50vw, sm: 100vw
 * 2-col-max:     md: 50vw, sm: 100vw
 * 1-col-max:     sm: 100vw
 */

export const mediaQMap = {
  'sm': {
    breakpoint: '640px',
    mediaQ: '(min-width: 640px)',
  },
  'md': {
    breakpoint: '768px',
    mediaQ: '(min-width: 768px)',
  },
  'lg': {
    breakpoint: '1024px',
    mediaQ: '(min-width: 1024px)',
  },
  'xl': {
    breakpoint: '1280px',
    mediaQ: '(min-width: 1280px)',
  },
  '2xl': {
    breakpoint: '1536px',
    mediaQ: '(min-width: 1536px)',
  },
}

export const imageSizes = {
  '16%': ['(min-width: 1024px) 16vw', '(min-width: 768px) 33vw', '50vw'],
  '20%': ['(min-width: 1024px) 20vw', '(min-width: 768px) 33vw', '50vw'],
  '25%': ['(min-width: 1024px) 25vw', '(min-width: 768px) 50vw', '100vw'],
  '33%': ['(min-width: 1024px) 33vw', '(min-width: 768px) 50vw', '100vw'],
  '50%': ['(min-width: 768px) 50vw', '100vw'],
  '100%': ['100vw'],
}

export const aspectRatioMap = {
  '16:9': 16 / 9,
  '4:3': 4 / 3,
  '3:4': 3 / 4,
  '1:1': 1,
  'original': undefined,
}

export const colWidthCenterMap = {
  '12 columns': 'col-span-12 col-start-1',
  '10 columns': 'col-span-12 col-start-1 lg:col-span-10 lg:col-start-2',
  '8 columns': 'col-span-12 col-start-1 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3',
}

export const colWidthLeftMap = {
  '12 columns': 'col-span-12 col-start-1',
  '10 columns': 'col-span-12 col-start-1 lg:col-span-10 lg:col-start-1',
  '8 columns': 'col-span-12 col-start-1 lg:col-span-10 lg:col-start-1 xl:col-span-8 xl:col-start-1',
}

export const bgColorMap = {
  'primary': 'bg-primary text-white',
  'primary-50': 'bg-primary-50',
  'secondary': 'bg-secondary text-white',
  'secondary-50': 'bg-secondary-50',
  'gray-50': 'bg-gray-50',
  'campaign-blue-800': 'bg-campaign-blue-800',
  'campaign-gold-50': 'bg-campaign-gold-50',
  'campaign-gray-100': 'bg-campaign-gray-100',
  'white': 'bg-white',
  'transparent': 'bg-transparent',
}

export const bgImageMap = {
  cacti: 'bg-cacti',
  coastline: 'bg-coastline',
  floral: 'bg-floral',
  petals: 'bg-petals',
  succulent: 'bg-succulent',
  yucca: 'bg-yucca',
}

export const campaignBgImageMap = {
  'white-starburst': 'bg-white-starburst',
  'gold-starburst': 'bg-gold-starburst',
  'gold-starburst-right': 'bg-gold-starburst-right',
}

export const columnsMaxCardMap: Record<'2' | '3' | '4' | '5', '2-up' | '3-up' | '4-up' | '5-up'> = {
  '2': '2-up',
  '3': '3-up',
  '4': '4-up',
  '5': '5-up',
}

export const heroHeightMap = {
  'short': 'md',
  'tall': 'lg',
} as const

export const campaignHeroHeightMap = {
  short: 'h-[350px]',
  tall: 'h-[480px] lg:h-[548px]',
}

const commonDimensions = [
  {
    mediaQuery: 'default',
    params: {
      w: 400,
      h: undefined,
    },
  },
  {
    mediaQuery: 'sm',
    params: {
      w: 640,
      h: undefined,
    },
  },
  {
    mediaQuery: 'md',
    params: {
      w: 768,
      h: 300,
    },
  },
  {
    mediaQuery: 'lg',
    params: {
      w: 1024,
      h: undefined,
    },
  },
  {
    mediaQuery: 'xl',
    params: {
      w: 1280,
      h: undefined,
    },
  },
  {
    mediaQuery: '2xl',
    params: {
      w: 1536,
      h: undefined,
    },
  },
]

export const campaignHeroDimensionMap: { [key: string]: Dimensions[] } = {
  short: commonDimensions,
  tall: [
    ...commonDimensions,
    {
      mediaQuery: 'lg',
      params: {
        w: 1024,
        h: 545,
      },
    },
    {
      mediaQuery: 'xl',
      params: {
        w: 1280,
        h: 545,
      },
    },
    {
      mediaQuery: '2xl',
      params: {
        w: 1536,
        h: 545,
      },
    },
  ],
}

export const heroDimensionMap: { [key: string]: Dimensions[] } = {
  short: commonDimensions,
  tall: [
    ...commonDimensions,
    {
      mediaQuery: 'lg',
      params: {
        w: 1024,
        h: 400,
      },
    },
    {
      mediaQuery: 'xl',
      params: {
        w: 1280,
        h: 400,
      },
    },
    {
      mediaQuery: '2xl',
      params: {
        w: 1536,
        h: 400,
      },
    },
  ],
}

export const dimensionMap = {
  campaignHero: campaignHeroDimensionMap,
  hero: heroDimensionMap,
}

export const backgroundColorMap = {
  'gray-50': 'bg-gray-50',
  'primary': 'bg-primary',
  'primary-50': 'bg-primary-50',
  'secondary': 'bg-secondary',
  'secondary-50': 'bg-secondary-50',
}

export const campaignBackgroundColorMap = {
  'campaign-gray-100': 'bg-campaign-gray-100',
  'campaign-gold-50': 'bg-campaign-gold-50',
  'white': 'bg-white',
}

export const alignMap = {
  'left': 'justify-start',
  'center': 'justify-center',
  'right': 'justify-end'
}

export const buttonStyleMap = {
  'primary': {
    'variant': 'filled',
    'color': 'primary'
  },
  'primary-outline': {
    'variant': 'outlined',
    'color': 'primary'
  },
  'light': {
    'variant': 'filled',
    'color': 'light'
  },
  'light-outline': {
    'variant': 'outlined',
    'color': 'light'
  }
}

export const cardsPerRowMap = {
  '2': 2,
  '3': 3,
  '4': 4,
} as const

export const containerSizeMap = {
  '8 columns': 8,
  '10 columns': 10,
  '12 columns': 12,
} as const

export const contentListColumnsMap = {
  '1': 1,
  '2': 2,
  '3': 3,
} as const

export const pyMap = {
  '0px': 'none',
  '20px': 'sm',
  '40px': 'md',
  '48px': 'lg',
  '60px': 'xl',
  '80px': '2xl',
} as const

export const imageViewportWidthMap = {
  '2': '50vw',
  '3': '33vw',
  '4': '25vw',
}
