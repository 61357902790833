import { ComponentPropsWithoutRef } from 'react'
import NextImage from '@components/next-image'
import getImageSizes from '@helpers/getImageSizes'
import { RawImageType } from '@lib/types'

import { CaptionedMedia as UiComponent } from '@shc/ui'

export interface CaptionedMediaProps extends ComponentPropsWithoutRef<'figure'> {
  image?: RawImageType
  imageSizes?: any
  children?: any
  rounded?: boolean
}

const CaptionedMedia = ({
  image,
  imageSizes,
  children,
  rounded = false
}: CaptionedMediaProps) => {
  const imageDimensions = image?.file.details.image
  const imageWidth = imageDimensions?.width

  return (
    <UiComponent
      caption={children}
      aspectRatio={imageSizes}
      slots={{
        media: NextImage
      }}
      slotProps={{
        media: {
          alt: image?.description ? image?.description : image?.title,
          src: image?.file.url,
          height: imageDimensions?.height,
          sizes: getImageSizes('100vw', '100vw', '100vw', `${imageWidth}px`, `${imageWidth}px`, `${imageWidth}px`),
          width: imageWidth,
        }
      }}
      rounded={rounded}
    />
  )
}

export default CaptionedMedia
