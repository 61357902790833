'use client'

import { useContext } from 'react'
import { HeroBlock as Block } from '@shc/ui'
import slugify from 'slugify'
import type { TypeHeroBlockFields } from '@lib/generated-types/TypeHeroBlock'
import { richTextRender } from '@lib/rich-text-base'
import NextImage from '@components/next-image'
import getImageSizes from '@helpers/getImageSizes'
import useAnalytics, { type SPEvent, type SPContext } from '@hooks/use-analytics'
import { routeMapper } from '@lib/menu-item-mapper'
import { heroHeightMap } from '@lib/token-maps'
import { BlockContext } from '@lib/analytics'

interface ImgFields {
  internalName: string
  image: any
  file: string
  focalPoint: string
}

const HeroBlock = ({
  backgroundColor,
  height,
  image,
  preheading,
  heading,
  description,
  button1Link,
  button1AnchorOrExternalLink,
  button1Label,
  button2Link,
  button2AnchorOrExternalLink,
  button2Label,
  imageFocalPoint,
  imageOverlayOpacity,
  sectionLink,
  variant,
}: TypeHeroBlockFields) => {
  const imageFields = image.fields as ImgFields
  const link1 = routeMapper(button1Link, button1AnchorOrExternalLink)
  const link2 = routeMapper(button2Link, button2AnchorOrExternalLink)
  const imageFocalPoint0 = Number(imageFocalPoint?.[0])
  const imageFocalPoint1 = Number(imageFocalPoint?.[1])
  const imageDimensions = imageFields.image.fields.file.details.image
  const { track } = useAnalytics()
  const blockContext = useContext(BlockContext)

  const handleAnalytics = (label: string, link: string) => {
    track({
      event: {
        name: 'component_click',
        data: {}
      } as SPEvent,
      contexts: [
        {
          name: 'component',
          data: {
            component_text: label,
            component_url: link,
          },
        },
        blockContext
      ] as SPContext[],
    })
  }

  return (
    <Block
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      preHeading={preheading}
      heading={heading}
      description={
        <>{richTextRender(description)}</>
      }
      buttonPrimary={(button1Link || button1AnchorOrExternalLink) && button1Label ? {
        color: 'primary',
        href: link1.route,
        label: button1Label,
        onClick: () => handleAnalytics(button1Label, link1.route),
        rel: link1.isInternal ? undefined : 'noopener noreferrer',
        target: link1.isInternal ? undefined : '_blank',
      } : undefined }
      buttonSecondary={(button2Link || button2AnchorOrExternalLink) && button2Label ? {
        color: 'primary',
        href: link2.route,
        label: button2Label,
        onClick: () => handleAnalytics(button2Label, link2.route),
        rel: link2.isInternal ? undefined : 'noopener noreferrer',
        target: link2.isInternal ? undefined : '_blank',
      } : undefined }
      imageFocalPoint={imageFocalPoint && [imageFocalPoint0, imageFocalPoint1]}
      imageOverlayOpacity={imageOverlayOpacity}
      mobileBackgroundColor={backgroundColor}
      size={heroHeightMap[height]}
      slots={{
        image: NextImage
      }}
      slotProps={{
        image: {
          alt: imageFields.image.fields.description,
          height: imageDimensions.height,
          // @ts-ignore
          priority: true,
          sizes: getImageSizes('200vw', '150vw', '100vw', '100vw', '100vw', '100vw'),
          src: imageFields.image.fields.file?.url,
          width: imageDimensions.width,
        }
      }}
      variant={variant}
    />
  )
}

export default HeroBlock
