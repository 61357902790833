"use client"

import clsx from 'clsx'
import throttle from 'lodash/fp/throttle'
import { ComponentPropsWithoutRef, useEffect, useState } from 'react'
import { Icon } from '@shc/ui'

import { getIcon } from '@components/icons'
import { Menu } from '@lib/types'
import NavLink from '@components/nav-link'
import { doScroll } from '../hooks/useCustomScroll'
export interface NavBarProps extends ComponentPropsWithoutRef<'nav'> {
  alignment?: 'center' | 'left'
  menu: Menu
  activePathname: string
  cta?: JSX.Element
}

const NavBar = ({
  alignment = 'center',
  id = '',
  menu,
  activePathname,
  cta,
  className,
  ...props
}: NavBarProps) => {
  const [activeSection, setActiveSection] = useState(
    activePathname.substring(activePathname.indexOf('#') + 1)
  )

  const sectionElementIds = menu
    .map(([link]) => {
      let href = link.route
      let sectionIndex = href.indexOf('#')
      if (sectionIndex != -1) {
        return href.substring(sectionIndex + 1)
      }
      return null
    })
    .filter((ref) => ref !== null)

  const throttleMs = 100
  const handleScroll = throttle(throttleMs, () => {
    const position = window.scrollY
    for (let sectionElementId of sectionElementIds) {
      if (sectionElementId !== null) {
        let ele = document.getElementById(sectionElementId)
        if (
          ele !== null &&
          position >= ele.getBoundingClientRect().top + window.scrollY - window.scrollOffSet
        ) {
          setActiveSection(sectionElementId)
        }
      }
    }
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <nav
      id={id !== '' ? id : undefined}
      className={clsx('sticky z-[5] bg-primary-50 top-16', className)}
      data-block="navBar"
      {...props}>
      <ul
        className={clsx(
          'container mx-auto flex flex-row flex-nowrap',
          'overflow-y-auto md:overflow-y-visible',
          'snap-x snap-mandatory',
          alignment === 'left' && 'justify-start',
          alignment === 'center' && 'justify-start lg:justify-center'
        )}>
        {menu.map(([link], idx) => (
          <li
            key={idx}
            className={clsx(
              'shrink-0',
              alignment === 'left' && 'first:-ml-5 first:xl:-ml-8',
              alignment === 'center' && 'first:-ml-5 first:lg:ml-0'
            )}>
            <NavLink
              href={link.route}
              target={link.isInternal ? undefined : '_blank'}
              rel={link.isInternal ? undefined : 'noopener noreferrer'}
              className="pt-3 pb-2.5 lg:pt-4.5 lg:pb-4.25 xl:px-8 px-5 no-wrap snap-center text-sm !font-semibold"
              onClick={(e) => {
                // If Anchoring to same page, custom scrool to section and update the history
                const hasAnchorIndex = String(e.currentTarget).indexOf('#')
                if (hasAnchorIndex !== -1) {
                  const [currentPath] = String(window.location.href).split('#')
                  const [targetPath, targetAnchor] = String(e.currentTarget).split('#')
                  if (currentPath === targetPath) {
                    // Scroll
                    const element = document.getElementById(targetAnchor)
                    if (element !== null) {
                      doScroll(
                        element!.getBoundingClientRect().top + window.scrollY - window.scrollOffSet
                      )
                    }

                    // Update url without reload
                    window.history.replaceState(true, '', String(e.currentTarget))

                    e.preventDefault()
                  }
                }
              }}
              active={link.route.substring(link.route.indexOf('#') + 1) === activeSection}>
              {link.icon !== undefined && (
                <Icon icon={getIcon(link.icon)} className="pr-1.5" />
              )}
              {link.name}
            </NavLink>
          </li>
        ))}
        {!!cta && (
          <li className="w-full bg-white fixed bottom-0 inset-x-0 flex justify-center items-center py-3 border-t-[1px] border-gray-100 lg:border-t-0 lg:py-0 lg:bg-inherit lg:static lg:justify-end lg:items-center">
            {cta}
          </li>
        )}
      </ul>
    </nav>
  )
}

export default NavBar
