const getImageSizes = (
  xs: string,
  sm: string,
  md: string,
  lg: string,
  xl: string,
  xl2: string
): string => `
  (max-width: 639px) ${xs},
  (max-width: 767px) ${sm},
  (max-width: 1023px) ${md},
  (max-width: 1279px) ${lg},
  (max-width: 1535px) ${xl},
  ${xl2}
`

export default getImageSizes
