"use client"

import React, { ComponentPropsWithRef, useRef, useState, KeyboardEvent, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y, Thumbs } from 'swiper'
import { richTextRender } from '@lib/rich-text-base'
import { VideoPlayer } from '@shc/ui'
import { TypeVideoPlayerFields } from '@lib/generated-types'
import { Entry } from 'contentful'
import clsx from 'clsx'
import type { Swiper as SwiperType } from 'swiper'
import Heading from '@components/heading'
import { Button, Icon } from '@shc/ui'

interface MediaVideoCarouselProps extends ComponentPropsWithRef<'div'> {
  media: Entry<TypeVideoPlayerFields>[]
  showThumbs?: boolean
}

const MediaVideoCarousel = ({ media, className, showThumbs = false }: MediaVideoCarouselProps) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [_, setInit] = useState<boolean>()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [swiper, setSwiper] = useState<SwiperType>()
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null)

  useEffect(() => {
    const onPageLoad = () => {
      setTabIndex(activeIndex)
    }

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pauseAll = () => {
    const frames = Array.from(document.getElementsByTagName('iframe'))
    for (const frame of frames) {
      frame.contentWindow?.postMessage(
        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
        'https://www.youtube.com'
      )
    }
  }

  const setTabIndex = (activeIndex: number) => {
    const slides = Array.from(
      document.getElementsByClassName('swiper-wrapper')[0].getElementsByClassName('swiper-slide')
    )
    for (let i = 0; i < slides.length; i++) {
      const iframe: HTMLIFrameElement = slides[i].children[0].children[0]
        .children[0] as HTMLIFrameElement
      const captionAnchors = Array.from(slides[i].children[1].getElementsByTagName('a'))

      if (i === activeIndex) {
        iframe.removeAttribute('tabindex')
      } else {
        iframe.setAttribute('tabindex', '-1')
      }

      for (let a = 0; a < captionAnchors.length; a++) {
        if (i === activeIndex) {
          captionAnchors[a].removeAttribute('tabindex')
        } else {
          captionAnchors[a].setAttribute('tabindex', '-1')
        }
      }
    }
  }

  const selectSlide = (event: KeyboardEvent, slideIndex: number) => {
    if (typeof swiper !== 'undefined' && (event.key === 'Enter' || event.key === ' ')) {
      swiper.slideTo(slideIndex)
    }
  }

  return (
    <div className={className}>
      <Swiper
        onInit={(swiper) => {
          setSwiper(swiper)
          setInit(true)
        }}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex)
          setTabIndex(swiper.activeIndex)
          pauseAll()
        }}
        loop={false}
        watchOverflow={false}
        a11y={{
          enabled: true,
          firstSlideMessage: 'This is the first slide',
          lastSlideMessage: 'This is the last slide',
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Navigation, A11y, Thumbs]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        slidesPerView={1}
        spaceBetween={20}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}>
        {media?.map((slides: Entry<TypeVideoPlayerFields>, idx: number) => {
          return (
            <SwiperSlide key={idx}>
              <VideoPlayer
                url={slides.fields.videoUrl}
                autoPlay={slides.fields.autoPlay} 
                loop={slides.fields.loop}
                description={false} 
              />
              <div className="text-sm text-left pt-5 caption">
                {media[idx].fields.title && (
                  <Heading level={3} size="h3" className="pb-2">
                    {media[idx].fields.title}
                  </Heading>
                )}
                <strong>
                  {idx + 1} of {media.length}
                </strong>
                {media[idx]?.fields?.caption && ' : '}
                {media[idx]?.fields?.caption && richTextRender(media[idx].fields.caption)}
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {showThumbs && (
        <div className="flex flex-row">
          {media.length > 4 && (
            <div className="hidden lg:flex flex-row items-center justify-center pr-3">
              <Button
                variant="outlined"
                size="sm"
                shape="circle"
                aria-label="Previous Slide"
                ref={prevRef}
                className="swiperPrev cursor-pointer"
                disabled={activeIndex === 0 ? true : false}>
                <Icon className="h-6" icon="chevron-left" />
              </Button>
            </div>
          )}
          <Swiper
            onSwiper={setThumbsSwiper}
            a11y={{
              enabled: true,
              firstSlideMessage: 'This is the first slide',
              lastSlideMessage: 'This is the last slide',
            }}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, A11y, Thumbs]}
            spaceBetween={16}
            watchSlidesProgress={true}
            className="pt-6 md:pt-8 w-full h-full"
            breakpoints={{
              320: {
                slidesPerView: media.length >= 3 ? 2.5 : media.length,
              },
              640: {
                slidesPerView: media.length >= 4 ? 3.5 : 3,
              },
              1024: {
                slidesPerView: 3,
              },
            }}>
            {media?.map((slides: Entry<TypeVideoPlayerFields>, idx: number) => {
              let videoId = slides.fields.videoUrl?.split('v=')[1]
              const ampersandPosition = videoId?.indexOf('&')
              if (ampersandPosition != -1) {
                videoId = videoId?.substring(0, ampersandPosition)
              }

              if (!videoId) {
                videoId = slides.fields.videoUrl?.split('/')[3]
              }

              return (
                <SwiperSlide key={idx} className="group px-1">
                  <button
                    aria-label={`Go to slide ${idx + 1}`}
                    onKeyDown={(e: KeyboardEvent) => selectSlide(e, idx)}
                    className="focus:outline focus:outline-[3px] outline-warning focus:rounded">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={`http://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                      alt={slides.fields.title ? slides.fields.title : slides.fields.internalName}
                      className={clsx(
                        'rounded group-hover:opacity-100',
                        idx === activeIndex ? 'opacity-100' : 'opacity-50'
                      )}
                    />
                  </button>
                  {slides.fields.title && (
                    <div
                      className={clsx(
                        'hidden md:block text-sm font-semibold group-hover:underline',
                        idx === activeIndex && 'underline'
                      )}>
                      {slides.fields.title}
                    </div>
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
          {media.length > 4 && (
            <div className="hidden lg:flex flex-row items-center justify-center pl-3">
              <Button
                variant="outlined"
                size="sm"
                shape="circle"
                aria-label="Next Slide"
                ref={nextRef}
                className="swiperNext cursor-pointer"
                disabled={media.length - 1 === activeIndex ? true : false}>
                <Icon className="h-6" icon="chevron-right" />
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MediaVideoCarousel
