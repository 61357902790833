"use client"

import { useState, useEffect, useCallback } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { Transition } from '@headlessui/react'

import { Menu as IMenu } from '@lib/types'
import useActivePathname from '@lib/use-active-pathname'

// components
import Analytics from '@components/analytics'
import NavLink from '@components/nav-link'
import { Button, Icon } from '@shc/ui'
import MenuDrawer from '@components/menu-drawer'

export interface HeaderProps extends ComponentPropsWithoutRef<any> {
  menu: IMenu
  menuDrawer?: IMenu
  menuSearch?: IMenu
  menuSuperheader?: IMenu
  menuDrawerFeatured?: IMenu
  addMenuToMenuDrawer?: boolean
  showLogin: boolean
  showMenuButton?: 'mobile only' | 'always'
  logoLink?: string
  showSearch?: boolean
  searchUrl?: string
  baseSearchFilter?: string
  cta?: JSX.Element
  drawerHeader?: JSX.Element
  drawerChildren?: JSX.Element
  getSession?: boolean
}

const Header = ({
  menu,
  menuDrawer = [],
  menuSearch = [],
  menuSuperheader = [],
  menuDrawerFeatured = [],
  addMenuToMenuDrawer = false,
  showLogin = true,
  showMenuButton = 'always',
  logoLink = '/',
  showSearch = true,
  searchUrl,
  baseSearchFilter,
  cta,
  drawerHeader,
  drawerChildren,
  className,
  children,
  getSession,
  ...props
}: HeaderProps) => {
  const [centerNavFlyout, setCenterNavFlyout] = useState(-1)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false)

  const activePathname = useActivePathname()

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setCenterNavFlyout(-1)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  const searchClick = (): void => {
    setIsSearchVisible(true)
  }

  // CSS helpers
  const getBackgroundClass = (centerNavFlyout: number): string => {
    let bgClass = ''
    // set styles if there is NO submenu or "flyout"
    if (centerNavFlyout === -1) {
      bgClass = 'bg-white'
    }

    // set styles if there IS a submenu or "flyout"
    if (centerNavFlyout > -1) {
      bgClass = 'bg-white'
      // adding a drop shadow when there is no submenu
      if (menu[centerNavFlyout][1].length === 0) {
        bgClass = `${bgClass} drop-shadow-sm-bottom`
      }
    }
    return bgClass
  }

  // const translate = useTranslations('Header')
  const activePathProviders = activePathname.startsWith('/providers')

  const scmgLogoPublic = () => (
    <Link className='min-[640px]:ml-0 inline-flex text-sm md:text-base font-bold whitespace-normal text-center min-[640px]:text-left min-[640px]:whitespace-nowrap' href='/' target='_self' rel='noopener noreferrer'>Sharp Community Medical Group</Link>
  )

  const scmgLogoProvider = (deviceSize: string) => {
    const size = deviceSize === 'mobile' ? 'xl:hidden inline-flex' : 
                  deviceSize === 'desktop' ? 'hidden xl:inline-flex' : ''
    
    return (
      <Link className={`${size} min-[640px]:ml-0 text-sm md:text-base font-bold whitespace-normal text-center min-[640px]:text-left min-[640px]:whitespace-nowrap`} href='/' target='_self' rel='noopener noreferrer'>Sharp Community Medical Group</Link>
    )
  }

  return (
    <>
      {children}
      <header
        className={clsx(
          'sticky top-0 transition-colors z-20 drop-shadow-sm-bottom',
          getBackgroundClass(centerNavFlyout),
          className
        )}
        {...props}>
        {/* HeaderWrap */}
        <div className={`container flex flex-row items-center min-[640px]:justify-between h-16 mx-auto ${activePathProviders ? 'gap-x-5' : 'gap-x-9'}`}>
          {/* LeftNav */}
          <div className={`inline-flex flex-row justify-start ${activePathProviders ? 'items-center gap-x-8 basis-1/3 lg:basis-auto' : 'xl:hidden'}`} >

              <Button
                id="menu-drawer-button"
                aria-label="Open menu"
                color="transparent"
                width="auto"
                size="sm"
                onClick={() => {
                  setIsMenuOpen(true)
                }}
                className={clsx(
                  'text-gray-700 !px-0',
                  showMenuButton === 'mobile only' && 'lg:hidden'
                )}>
                <Icon icon={['fak', 'menu']} className="!h-[26px] py-2" size="2xl" />
              </Button>
        
            <Analytics
              click={{
                name: 'navigation_click',
                data: {
                  navigation_tree: 'Home',
                  navigation_level: 1,
                  navigation_subject: 'Logo',
                  navigation_url: 'https://www.scmg.org/',
                },
              }}
              contexts={[{ name: 'section', data: { section_name: 'header' } }]}
            >
              {scmgLogoProvider('desktop')}
            </Analytics>
          </div>

          {/* LogoMobile */}
          <Analytics
            click={{
              name: 'navigation_click',
              data: {
                navigation_tree: 'Home',
                navigation_level: 1,
                navigation_subject: 'Logo',
                navigation_url: 'https://www.scmg.org/',
              },
            }}
            contexts={[{ name: 'section', data: { section_name: 'header' } }]}
          >
            {activePathProviders ? scmgLogoProvider('mobile') : scmgLogoPublic()}

            
          </Analytics>
          {/* </div> */}

          {/* CenterNav */}
          <ul
            className={`inline-flex flex-row items-center ${activePathProviders ? 'hidden xl:flex' : 'hidden xl:inline-flex'} `}
            onMouseLeave={() => setCenterNavFlyout(-1)}>
            {menu.map(([link1, children1], idx1) => {

              // Displays first / main level of navigation
              return (
                <li key={idx1} className={clsx(centerNavFlyout === idx1 && 'bg-white')}>
                {children1.length === 0 && (
                  <Analytics
                    click={{
                      name: 'navigation_click',
                      data: {
                        navigation_tree: link1.name === 'Find a Doctor' ? 'Find a doctor' : link1.name,
                        navigation_subject: link1.name === 'Find a Doctor' ? 'Find a doctor' : link1.name,
                        navigation_level: 1,
                        navigation_url: link1.route,
                      },
                    }}
                    contexts={[{ name: 'section', data: { section_name: 'header' } }]}
                  >
                    <NavLink
                      as="a"
                      href={link1.route}
                      target={link1.isInternal ? undefined : '_blank'}
                      rel={link1.isInternal ? undefined : 'noopener noreferrer'}
                      active={link1.route.toLowerCase() === activePathname}
                      aria-current={link1.route.toLowerCase() === activePathname ? 'page' : undefined}
                      className="pt-4.5 pb-4.25 px-6 text-sm !font-semibold whitespace-nowrap"
                      onMouseOver={() => setCenterNavFlyout(idx1)}
                      >
                      {link1.name}
                    </NavLink>
                  </Analytics>
                )}

                {/* Displays secondary level of navigation */}
                {children1.length > 0 && (
                   <Analytics
                    click={{
                      name: 'navigation_click',
                      data: {
                        navigation_tree: `${link1.name} > ${link1.name}`,
                        navigation_subject: link1.name,
                        navigation_level: 1,
                        navigation_url: link1.route,
                      },
                    }}
                    contexts={[{ name: 'section', data: { section_name: 'header' } }]}
                   >
                    <NavLink
                      as="button"
                      active={activePathname.startsWith(link1.route.toLowerCase())}
                      className="pt-4.5 pb-4.25 px-6 text-sm !font-semibold"
                      onMouseOver={() => setCenterNavFlyout(idx1)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          if (centerNavFlyout === idx1) {
                            setCenterNavFlyout(-1)
                          } else {
                            setCenterNavFlyout(idx1)
                          }
                        }
                      }}
                      aria-haspopup={true}
                      aria-expanded={centerNavFlyout === idx1}>
                      {link1.name}
                    </NavLink>
                  </Analytics>
                )}
              </li>
              )
            })}
          </ul>
          {/* end: CenterNav */}

          {/* RightNav: */}
          {/* prospective physicians and provider-login */}
          {!activePathProviders && (
            <div className="hidden min-[640px]:inline-flex flex-row items-baseline p-3 md:p-0 ">
              <div className="whitespace-nowrap hidden xl:flex">
                <Analytics
                  click={{
                    name: 'navigation_click',
                    data: {
                      navigation_tree: 'Prospective physicians',
                      navigation_level: 1,
                      navigation_subject: 'Prospective physicians',
                      navigation_url: '/prospective-physicians',
                    },
                  }}
                  contexts={[{ name: 'section', data: { section_name: 'header' } }]}>
                  <NavLink
                    as="a"
                    active={activePathname.startsWith('/prospective-physicians')}
                    className="pt-4.5 pb-4.25 pr-5 text-sm !font-semibold whitespace-nowrap"
                    href='/prospective-physicians'
                  >
                    Prospective physicians
                  </NavLink>
                </Analytics>
              </div>
              { showLogin && (
                <div className="whitespace-nowrap hidden md:flex">
                  <Analytics
                    click={{
                      name: 'navigation_click',
                      data: {
                        navigation_tree: 'Provider login',
                        navigation_level: 1,
                        navigation_subject: 'Provider login',
                        navigation_url: '/providers',
                      },
                    }}
                    contexts={[{ name: 'section', data: { section_name: 'header' } }]}>
                    <Button
                      id="provider-login"
                      aria-label="Provider login"
                      color="primary"
                      shape="rounded"
                      variant='filled'
                      size="sm"
                      width="auto"
                      href="https://providers.scmg.org"
                      as="a"
                      target='_blank'
                    >
                      Provider login
                    </Button>
                  </Analytics>
                </div>
              )}
            </div>
          )}

          {/* search and my account */}
          {activePathProviders && (
            <div className="inline-flex flex-row items-center justify-end gap-x-10 xl:gap-x-15 basis-1/3 lg:basis-auto">
                {/* Mobile search button */}
                {showSearch && (
                  <>
                    <Analytics
                      click={{
                        name: 'navigation_click',
                        data: {
                          navigation_tree: 'Search',
                          navigation_level: 1,
                          navigation_subject: 'Search',
                        },
                      }}
                      contexts={[{ name: 'section', data: { section_name: 'header' } }]}
                    >
                      <Button
                        size="sm"
                        variant="flat"
                        name="Search"
                        width="auto"
                        className="md:hidden"
                        aria-label="Search"
                        onClick={searchClick}>
                        <Icon icon={'magnifying-glass'} className="h-5" />
                      </Button>
                    </Analytics>

                    <Analytics
                      click={{
                        name: 'navigation_click',
                        data: {
                          navigation_tree: 'Search',
                          navigation_level: 1,
                          navigation_subject: 'Search',
                        },
                      }}
                      contexts={[{ name: 'section', data: { section_name: 'header' } }]}
                    >
                      <NavLink
                        className="hidden md:block text-sm !font-semibold"
                        onClick={searchClick}
                        as="button"
                        data-testid="search-desktop">
                        <Icon icon={'magnifying-glass'} />
                        {/* <span className="hidden pl-1 md:inline">{translate('search')}</span> */}

                         <span className="hidden pl-1 md:inline">Search</span>
                      </NavLink>
                    </Analytics>
                  </>
                )}

                <NavLink
                  className="hidden lg:block text-sm !font-semibold"
                  href='/app'
                  as="button"
                  data-testid="my-account"
                >
                  My account
                </NavLink>
              {!!cta && cta}
            </div>
          )}
        </div>
        {/* end:HeaderWrap */}
      </header>
      
      <Transition
        unmount={false} // Do not remove from DOM (for screen readers and performance)
        className="fixed inset-0 z-10"
        show={centerNavFlyout > -1 && menu[centerNavFlyout][1].length > 0}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        onClick={() => setCenterNavFlyout(-1)}
      />

      {/* For mobile experience */}
      <MenuDrawer
        menu={addMenuToMenuDrawer ? menu.concat(menuDrawer) : menuDrawer}
        menuSuperheader={menuSuperheader}
        menuDrawerFeatured={menuDrawerFeatured}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        activePathname={activePathname}
        header={drawerHeader}
        showLogin={showLogin}
        getSession={getSession}>
        {drawerChildren}
      </MenuDrawer>
    </>
  )
}

export default Header
