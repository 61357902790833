'use client'

import Image, { type ImageLoaderProps } from 'next/image'

interface NextImageProps {
  alt: string
  className?: string
  fill?: boolean
  height?: number
  priority?: boolean
  quality?: number
  sizes?: string
  src: string
  width?: number
}

// Source: https://nextjs.org/docs/app/api-reference/config/next-config-js/images#contentful
// Docs: https://www.contentful.com/developers/docs/references/images-api/
export const contentfulImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  const url = new URL(`https:${src}`)

  url.searchParams.set('fm', 'webp')
  if (width) url.searchParams.set('w', width.toString())
  url.searchParams.set('q', (quality || 75).toString())

  return url.href
}

const NextImage = ({
  alt,
  className,
  fill,
  height,
  priority = false,
  quality,
  sizes,
  src,
  width,
}: NextImageProps) => (
  <Image
    alt={alt}
    className={className}
    fill={fill}
    height={height}
    loader={contentfulImageLoader}
    priority={priority}
    quality={quality}
    sizes={sizes}
    src={src}
    width={width}
  />
)

export default NextImage
