'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import { Alert, Button, Container, Icon, Typography } from '@shc/ui'

interface AlertSpoofingProps {
  spoofedName: any
  user: string
}

const AlertSpoofing = ({
  spoofedName,
  user,
}: AlertSpoofingProps) => {
  const [isSpoofing, setIsSpoofing] = useState(true)
  const pathname = usePathname()

  return (
    user?.access_token && isSpoofing && pathname.includes('/providers') && (
      <Container className="mt-3">
        <Alert
          className="justify-between sm:py-2"
          severity="error">
          <div className="hidden sm:flex" />{/* Pseudo-column to keep icon and name centered */}

          <div className="flex sm:items-center">
            <p>{/* Vertically aligns icon at xs viewport widths */}
              <Icon
                className="mr-2"
                icon="eye"
              />
            </p>

            <Typography>
              Spoofing {spoofedName}
            </Typography>
          </div>

          <div>
            <Button
              aria-label="Unspoof"
              color="light"
              onClick={() => setIsSpoofing(false)}
              size="xs"
              width="auto">
              <Typography className="!text-gray-700">
                Unspoof
              </Typography>
            </Button>
          </div>
        </Alert>
      </Container>
    )
  )
}

export default AlertSpoofing
