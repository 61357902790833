'use client'

import { useState } from 'react'
import { Alert } from '@shc/ui'
import { TypeIcon } from '@lib/generated-types'
import { getIconFromContentful } from '@lib/icons'
import type { Document } from '@contentful/rich-text-types'
import { richTextRender } from '@lib/rich-text-base'

interface AlertBarProps {
  description: Document
  icon?: TypeIcon
}

const AlertBar = ({
  description,
  icon,
}: AlertBarProps) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    isOpen && (
      <Alert
        className={'mt-3'}
        icon={getIconFromContentful(icon)}
        onClose={() => setIsOpen(false)}>
          {richTextRender(description)}
      </Alert>
    )
  )
}

export default AlertBar
