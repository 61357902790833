"use client"

import { richTextRender } from '@lib/rich-text-base'
import type { TypeVideoPlayerFields } from '@lib/generated-types'
import { ComponentPropsWithoutRef,useCallback, useEffect, useState } from 'react'
import { VideoPlayer as Block } from '@shc/ui'

export const getYoutubeVideoIdFromUrl = (urlString?: string) => {
  // early return if undefined or bad url
  if (!urlString) return
  // catch errors parsing url
  try {
    const url = new URL(urlString)
    // check if v search parameter
    if (url.searchParams.has('v')) return url.searchParams.get('v') ?? undefined
    // check if /embed/ in path
    if (url.pathname.includes('/embed/')) return url.pathname.split('/').pop()
  } catch (e) {
    console.error('VideoPlayer: error getting video id from youtube url.')
  }
}

interface VideoPlayerBlockProps extends ComponentPropsWithoutRef<'div'>, TypeVideoPlayerFields {
  showCaption: boolean
  videoUrl?: string
  autoPlay?: boolean
  fullScreen?: boolean
  loop?: boolean
}

const VideoPlayerBlock = ({
  videoUrl,
  caption,
  showCaption = true,
  fullScreen = false,
  autoPlay = false,
  loop = false,
}: VideoPlayerBlockProps) => {
  const [isFullScreen, setisFullScreen] = useState(fullScreen)

  const videoId = getYoutubeVideoIdFromUrl(videoUrl)

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (fullScreen)
        if (event.key === 'Escape') {
          setisFullScreen(false)
        } else if (event.key === ' ') {
          setisFullScreen(true)
        }
    },
    [fullScreen]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  return (
    <Block
      id={videoId}
      description={showCaption && caption && richTextRender(caption)}
      url={videoUrl}
      autoPlay={autoPlay}
      loop={loop}
    />
  )
}

export default VideoPlayerBlock
