'use client'

import { useContext, useEffect, useState } from 'react'
import { Collapsible, ExpandableContent as ExpandableContentSharp } from '@shc/ui'
import { richTextRenderFactory } from '@lib/rich-text-options'
import type {
  TypeExpandableContentFields,
  TypeExpandableContentItemFields,
} from '@lib/generated-types'
import useAnalytics, { type SPContext, type SPEvent } from '@hooks/use-analytics'
import { BlockContext, PageContext } from '@lib/analytics'

const ExpandableContent = ({
  sectionLink,
  items,
}: TypeExpandableContentFields) => {
  const richTextRenderChildren = richTextRenderFactory()
  const [events, setEvents] = useState({})
  const [activeEventId, setActiveEventId] = useState('')
  const { track } = useAnalytics()

  const pageContext = useContext(PageContext)
  const blockContext = useContext(BlockContext)

  useEffect(() => {
    const activeEvent = events[activeEventId]

    if (activeEvent) {
      const activeEventTitle = activeEvent.title

      const contexts: SPContext[] = [
        {
          name: 'component',
          data: {
            component_text: activeEventTitle,
          },
        }
      ]

      if (pageContext) contexts.push(pageContext)
      if (blockContext) contexts.push(blockContext)

      if (activeEvent.name === 'component_collapse') {
        track({
          event: { name: 'component_collapse', data: {} } as SPEvent,
          contexts,
        })
      } else {
        track({
          event: { name: 'component_expand', data: {} } as SPEvent,
          contexts,
        })
      }
    }
  }, [events])

  const handleClick = (title: string, sys: any) => {
    const id = sys.id

    if (events[id]?.name === 'component_expand') {
      setEvents(prevState => ({
        ...prevState,
        [id]: {
          name: 'component_collapse',
          title,
          sys,
        }
      }))

      setActiveEventId(id)
    } else {
      setEvents(prevState => ({
        ...prevState,
        [id]: {
          name: 'component_expand',
          title,
          sys,
        }
      }))

      setActiveEventId(id)
    }
  }

  return (
    <ExpandableContentSharp
      id={sectionLink}>
      {items.map(({ fields, sys }) => {
        const fieldsData = fields as TypeExpandableContentItemFields

        return (
          <Collapsible
            description={
              <>{richTextRenderChildren(fieldsData.description)}</>
            }
            heading={fieldsData.title}
            key={sys.id}
            onClick={() => handleClick(fieldsData.title, sys)}
          />
      )})}
    </ExpandableContentSharp>
  )
}

export default ExpandableContent
